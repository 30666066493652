<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">User Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="First Name" 
          :value="form.first_name"
          @input="updateForm({key: 'first_name', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.first_name?true:false">
          {{ validation.first_name?validation.first_name[0]:''}}
        </span>
       
        <vs-input 
          class="w-full mt-4" 
          label="Middle Name" 
          :danger="validation.middle_name?true:false"
          :danger-text="validation.middle_name?validation.middle_name[0]:''"
          :value="form.middle_name"
          @input="updateForm({key: 'middle_name', value: $event})"/>

        <vs-input 
          class="w-full mt-4" 
          label="Last Name" 
          :danger="validation.last_name?true:false"
          :danger-text="validation.last_name?validation.last_name[0]:''"
          :value="form.last_name"
          @input="updateForm({key: 'last_name', value: $event})"/>

        <vs-input 
          class="w-full mt-4" 
          label="Email" 
          :danger="validation.email?true:false"
          :danger-text="validation.email?validation.email[0]:''"
          :value="form.email"
          @input="updateForm({key: 'email', value: $event})"/>
        
        <vs-input 
          class="w-full mt-4" 
          label="Username" 
          :danger="validation.username?true:false"
          :danger-text="validation.username?validation.username[0]:''"
          :value="form.username"
          @input="updateForm({key: 'username', value: $event})"/>
        
        <vs-input 
          class="w-full mt-4" 
          label="Password" 
          :danger="validation.password?true:false"
          :danger-text="validation.password?validation.password[0]:''"
          :value="form.password"
          @input="updateForm({key: 'password', value: $event})"/>

        <vs-input 
          class="w-full mt-4" 
          label="Password confirmation" 
          :danger="validation.password_confirmation?true:false"
          :danger-text="validation.password_confirmation?validation.password_confirmation[0]:''"
          :value="form.password_confirmation"
          @input="updateForm({key: 'password_confirmation', value: $event})"/>

        <vs-checkbox 
          class="w-full mt-4" 
          :value="form.status"
          @input="updateForm({key: 'status', value: $event})"> 
          Status
        </vs-checkbox>

        <div class="mt-4 ">
      <label class="vs-input--label">User Type</label>
      <v-select 
        :value="form_dropdown.role_id"
        @input="updateForm({key: 'role_id', value: $event})"
        :options="user_types"/>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    vSelect
  },
  mounted() {
    this.fetchAndSetUserTypesDropdown();
  },
  computed: {
    ...mapGetters({
        form: 'user/getFormObj',
        validation: 'validation/getValidationErrors',
        form_dropdown: 'user/getFormDropdownObj',
        user_types: 'user/fetchUserTypesDropdown',
    }),
  },
  methods: {
    ...mapActions('user', [
      'updateForm',
      'fetchAndSetUserTypesDropdown'
    ]),
    ...mapMutations({
      CLEAR_FORM: 'user/CLEAR_FORM'
    }),
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
